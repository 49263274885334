<template>
  <div>
    <b-modal centered id="modal-winloss-general-detail" size="xl" cancel-variant="outline-secondary"
      :cancel-title="$t('Close')" ok-variant="primary" modal-class="modal-primary" :title="$t('View Winloss Detail')"
      @hidden="resetModal()">
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group label="Username" label-for="username" class="mb-0">
                <b-form-input
                    id="username"
                    v-model="usernameFilter"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('From date')" label-for="fromDate">
                <flat-pickr id="fromDate" v-model="fromDateFilter" class="form-control" placeholder="YYYY-MM-DD" />
                <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                  The field is require
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('To date')" label-for="toDate">
                <flat-pickr id="toDate" v-model="toDateFilter" class="form-control" placeholder="YYYY-MM-DD" />
                <div style="margin: 8px 0px; color: red" v-if="isToDate">
                  The field is require
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-end justify-content-end">
            <b-col cols="12" md="4" class="d-flex align-items-end justify-content-end">
              <b-button variant="outline-primary" @click="resetFilter()">
                Reset
              </b-button>
              <b-button variant="primary" class="ml-2" @click="searchFilter()">
                Search
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card no-body>
        <b-table ref="refSportTeamListTable" class="position-relative table-white-space mb-0 max-height-table"
          sticky-header head-variant="light" :items="dataDetailWinLoss" responsive :fields="tableColumns"
          primary-key="user_id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc">
          <template #cell(bet)="data">
            {{ numberFormat(data.item.bet / 1000) }}
          </template>

          <template #cell(win)="data">
            {{ numberFormat(data.item.win / 1000) }}
          </template>

          <template #cell(lost)="data">
            {{ numberFormat(data.item.lost / 1000) }}
          </template>

          <template #cell(cancel)="data">
            {{ numberFormat(data.item.cancel / 1000) }}
          </template>

          <template #cell(tie)="data">
            {{ numberFormat(data.item.tie / 1000) }}
          </template>

          <template #cell(winLoss)="data">
            <span v-if="data.item.winLoss > 0" class="text-primary">
              {{ numberFormat(data.item.winLoss) }}
            </span>
            <span v-if="data.item.winLoss <= 0" class="text-danger">
              {{ numberFormat(data.item.winLoss) }}
            </span>
          </template>

          <template #cell(action)="data">
            <b-button v-if="$can('read', 'agentwinlossgeneraldetail')" v-b-tooltip.hover.v-info
              :title="$t('Xem chi tiết')" variant="primary" class="btn-icon btn-sm mr-50" @click="viewDetail(data.item)">
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
        <div class="p-2">
          <b-row>
            <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
              <span class="text-muted">Showing {{ dataMeta.of }} entries</span>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </b-modal>
  </div>
</template>
  
<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BTable,
  BCard,
} from "bootstrap-vue";
import { ref, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { numberFormat } from "@core/utils/filter";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BTable,
    BCard,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      // isHasLogo: false,
      agentData: {
        id: "",
        name: "",
        status: "",
        start_maintenance: "",
        wallet_name: "",
        end_maintenance: "",
        // start_end_maintenance: "",
        logo: [],
      },
    };
  },
  props: {
    winlossDetail: {
      type: Object,
      default: null,
    },
    fromDate: {
      type: String,
      default: null,
    },
    toDate: {
      type: String,
      default: null,
    },
  },
  watch: {

  },
  computed: {

  },
  methods: {
    confirmUpdate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup(props) {
    if (!store.hasModule("agent"))
      store.registerModule("agent", agentStoreModule);
    if (!store.hasModule("sports"))
      store.registerModule("sports", sportsStoreModule);

    const tableColumns = [
      { key: "index", label: "#", sortable: false },
      { key: "username", label: "Username", sortable: false },
      { key: "bet", label: "Bet", sortable: true },
      { key: "win", label: "Win", sortable: true },
      { key: "lost", label: "Loss", sortable: true },
      { key: "cancel", label: "Cancel", sortable: true },
      { key: "tie", label: "Tie", sortable: true },
      { key: "winLoss", label: "Win/Loss", sortable: true },
    ];

    const toDateFilter = ref(props.toDate);
    const fromDateFilter = ref(props.fromDate);
    const usernameFilter = ref(null)
    const productIdFilter = ref(null);
    const totalSportTeam = ref(0);
    const dataDetailWinLoss = ref();
    const isFromDate = ref(false);
    const isToDate = ref(false);
    const currentPage = ref();
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const codeProduct = ref([]);

    const sportTeamLists = () => {
      if (props.winlossDetail && props.winlossDetail.product_id) {
        store
          .dispatch("agent/fetchWinLossGeneralDetail", {
            fromDate: fromDateFilter.value,
            toDate: toDateFilter.value,
            username: usernameFilter.value,
            productId: props.winlossDetail.product_id
          })
          .then((response) => {
            let result = response.data.data;
            if (result) {
              dataDetailWinLoss.value = Object.values(result).map((item, index) => {
                let winLoss = item.win / 1000 + item.cancel / 1000 + item.tie / 1000 - item.bet / 1000;
                return { ...item, winLoss: winLoss, index: index + 1 }
              });
              if (dataDetailWinLoss.value.length > 0) {
                let objectSum = sumAttributes(dataDetailWinLoss.value);
                dataDetailWinLoss.value.push({
                  bet: objectSum.bet,
                  win: objectSum.win,
                  lost: objectSum.lost,
                  cancel: objectSum.cancel,
                  tie: objectSum.tie,
                  winLoss: objectSum.winLoss
                })
              }
              totalSportTeam.value = dataDetailWinLoss.value.length;
            }
          });
      }
    }

    sportTeamLists();

    const sumAttributes = (list) => {
      let sums = {};
      list.forEach(obj => {
        for (let key in obj) {
          if (key === 'bet' || key === 'win' || key === 'lost' || key === 'cancel' || key === 'tie') {
            sums[key] = (sums[key] || 0) + parseFloat(obj[key]);
          } else if (key === 'winLoss') {
            sums[key] = (sums[key] || 0) + obj[key]
          }
        }
      });

      return sums;
    }

    const dataMeta = computed(() => {
      return {
        of: totalSportTeam.value,
      };
    });

    const resetFilter = () => {
      toDateFilter.value = null;
      fromDateFilter.value = null;
      usernameFilter.value = null;
    };

    const searchFilter = () => {
      isFromDate.value = false;
      isToDate.value = false;
      if (toDateFilter.value === "" || !toDateFilter.value) {
        isToDate.value = true;
      }
      if (fromDateFilter.value === "" || !fromDateFilter.value) {
        isFromDate.value = true;
      }

      if (toDateFilter.value && fromDateFilter.value && fromDateFilter.value !== "" && toDateFilter.value !== ""
      ) {
        isFromDate.value = false;
        isToDate.value = false;
        sportTeamLists();
      }
    };

    const resetModal = () => {
      toDateFilter.value = '';
      fromDateFilter.value = '';
      usernameFilter.value = '';
      dataDetailWinLoss.value = [];
    }

    return {
      toDateFilter,
      fromDateFilter,
      usernameFilter,
      dataDetailWinLoss,
      totalSportTeam,
      tableColumns,
      isFromDate,
      isToDate,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,
      codeProduct,
      resetFilter,
      numberFormat,
      searchFilter,
      resetModal
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>